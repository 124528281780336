<script>
import {hasEditPermission,currentRoleLevelName} from '@/mixins/roleLevelPermissions';

export default {
	props: ['agreementData'],
	data(){
		return{
			isViewMode : !hasEditPermission('section-basics')
		}
	},
	computed:{
		clientUserAgreement(){
			return currentRoleLevelName == 'client-sharing-user' ? true : false;
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Child Information</h4>

		<list-item
			v-slot="{ item: child, index:index }"
			v-model="agreementData.children"
			class="mb-5"
			new-label="Add Child"
			:object-prototype="
				() => {
					return { name: {} };
				}
			"
			:testid="'child-'"
			:disabled="isViewMode"
		>
			<child-input-group v-model="child" :testid="'child-'+index" :disabled="isViewMode"/>
		</list-item>
		<additional-clauses
			v-if="!clientUserAgreement"
			v-model="agreementData.childrenDetails.additionalClauses" :testid="'child-add-clause-'"
			:disabled="isViewMode"
		/>
	</b-card>
</template>
