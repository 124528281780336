<script>
import partnerChoices from '@/mixins/partnerChoices';
import {hasEditPermission,currentRoleLevelName} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [partnerChoices],
	props: ['agreementData'],
	data(){
		return{
			isViewMode : !hasEditPermission('section-basics')
		}
	},
	computed: {
		relationship() {
			return this.agreementData.relationship;
		},
		clientUserAgreement(){
			return currentRoleLevelName == 'client-sharing-user' ? true : false;
		}
	},
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Relationship</h4>

		<radio-group
			v-if="clientUserAgreement"
			v-model="relationship.status"
			class="mb-3"
			input-cols="8"
			select
			label="What is your current relationship status?"
			:options="[
				'Married',
				'Cohabited for over two years',
				'Cohabited for under two years without children',
				'Have children but are not spouses',
			]"
			:testid="'relationship-current-status'"
			:disabled="isViewMode"
		/>

		<radio-group
			v-else
			v-model="relationship.status"
			class="mb-3"
			input-cols="8"
			select
			label="What is the current relationship status of the parties?"
			:options="[
				'Married',
				'Cohabited for over two years',
				'Cohabited for under two years without children',
				'Have children but are not spouses',
			]"
			:testid="'relationship-current-status'"
			:length="'long'"
			:disabled="isViewMode"
		/>

		<conditional-group
			v-if="clientUserAgreement"
			class="my-3"
			:condition="
				relationship.status !== 'Have children but are not spouses'
			"
		>
			<date-input
				v-model="relationship.cohabitationDate"
				class="mb-3"
				label="When did you start living together?"
				:testid="'relationship-cohabitation-date'"
				:disabled="isViewMode"
			/>

			<date-input
				v-if="relationship.status === 'Married'"
				v-model="relationship.marriageDate"
				class="mb-3"
				label="When did you get married?"
				:testid="'relationship-marriage-date'"
				:disabled="isViewMode"
			/>

			<input-group
				v-if="relationship.status === 'Married'"
				v-model="relationship.marriageLocation"
				class="mb-3"
				input-cols="8"
				label="Where did you get married?"
				:testid="'relationship-marriage-location'"
				:disabled="isViewMode"
			/>

			<date-input
				v-model="relationship.separationDate"
				class="mb-3"
				label="When did you separate?"
				:testid="'relationship-separation-date'"
				:disabled="isViewMode"
			/>

			<yes-no-choice
				v-model="relationship.livingTogether"
				class="mb-3"
				label="Have you been living together in the same residence since your separation?"
				:testid="'relationship-living-separately'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<conditional-group
			v-else
			class="my-3"
			:condition="
				relationship.status !== 'Have children but are not spouses'
			"
		>
			<date-input
				v-model="relationship.cohabitationDate"
				class="mb-3"
				label="What was the date on which cohabitation commenced?"
				:testid="'relationship-cohabitation-date'"
				:disabled="isViewMode"
			/>

			<date-input
				v-if="relationship.status === 'Married'"
				v-model="relationship.marriageDate"
				class="mb-3"
				label="Date on which the parties were married"
				:testid="'relationship-marriage-date'"
				:disabled="isViewMode"
			/>

			<input-group
				v-if="relationship.status === 'Married'"
				v-model="relationship.marriageLocation"
				class="mb-3"
				input-cols="8"
				label="Location of marriage"
				:testid="'relationship-marriage-location'"
				:disabled="isViewMode"
			/>

			<date-input
				v-model="relationship.separationDate"
				class="mb-3"
				label="Date on which the parties separated"
				:testid="'relationship-separation-date'"
				:disabled="isViewMode"
			/>

			<yes-no-choice
				v-model="relationship.livingTogether"
				class="mb-3"
				label="Have the parties been living separately in the same residence?"
				:testid="'relationship-living-separately'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<!-- <conditional-group
			class="my-3"
			:condition="relationship.status === 'Married'"
		>
			<b-form-group
				id="input-divorceApplication"
				input-cols="8"
				label="Which party will apply for divorce?"
				label-for="divorceApplication"
			>
				<b-form-select
					id="divorceApplication"
					v-model="relationship.divorceApplication"
					size="sm"
					:options="[
						...partnerChoices,
						{ text: 'Either', value: 'either' },
					]"
					:data-testid="'relationship-divorce-application'"
				/>
			</b-form-group>

			<b-form-group
				id="input-divorcePayment"
				input-cols="8"
				label="Who will pay for the divorce application?"
				label-for="divorcePayment"
			>
				<b-form-select
					id="divorcePayment"
					v-model="relationship.divorcePayment"
					size="sm"
					:options="[
						...partnerChoices,
						{
							text: 'The parties will share the cost',
							value: 'shared',
						},
					]"
					:data-testid="'relationship-divorce-payment'"
				/>
			</b-form-group>
		</conditional-group> -->

		<additional-clauses v-if='!clientUserAgreement' v-model="relationship.additionalClauses" :testid="'relationship-add-clause-'"  :disabled="isViewMode"/>
	</b-card>
</template>
