<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
// import Editor from '@tinymce/tinymce-vue';

import generateDocument from '@/lib/agreement/generate';
import permissions from '@/mixins/permissions';
import { analytics } from '../firebase';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
// const VUE_APP_TINY_MCE_API_KEY = process.env.VUE_APP_TINY_MCE_API_KEY;
import { hasEditPermission } from '@/mixins/roleLevelPermissions';

export default {
	props: [
		'agreementData'
	],
	name: 'Collaboration',
	mixins: [permissions],
	computed: {
		...mapState(['agreement', 'user', 'editedAgreement']),
		editorData: {
			get: function () {
				if (this.editedAgreement) {
					return this.editedAgreement;
				} else {
					return generateDocument({
						agreementData: this.agreementData,
					});
				}
			},
			set: function (value) {
				this.editorHtml = value;
			},
		},
		canDownload() {
			return this.editedAgreement;
		},
	},
	data() {
		return {
			editorHtml: '',
			// VUE_APP_TINY_MCE_API_KEY,
			classLevels: [
				'one',
				'two',
				'three',
				'four',
				'five',
				'six',
				'seven',
				'eight',
				'nine',
				'ten',
				'eleven',
			],
			editor: Editor,
			editorConfig: {
                    // The configuration of the editor.
					htmlSupport: {
						allow: [
							{
								name: 'div',
								attributes: true,
								classes: true,
								id:true
							},
							{
								name: 'span',
								attributes: true,
								classes: true
							},
							{
								name: 'p',
								attributes: true,
								classes: true
							},
							{
								name: 'li',
								attributes: true,
								classes: true
							},
							{
								name: 'ol',
								attributes: true,
								classes: true 
							},
							{
								name: 'b',
								attributes: true,
								classes: true 
							},
							{
								name: 'i'
							},
							{
								name: 'table',
								attributes: true,
								classes: true,
							},
							{
								name: 'tbody',
								attributes: true,
								classes: true ,
							},
							{
								name: 'tr',
								attributes: true,
								classes: true,
							},
							{
								name: 'td',
								attributes: true,
								classes: true,
							}
						]
					},
					autoParagraph :false,
					fillEmptyBlocks : false,
					highlight: {
						options: [
							{
								model: 'greenMarker',
								class: 'marker-green',
								title: 'Green marker',
								color: 'rgb(25, 156, 25)',
								type: 'marker'
							},
							{
								model: 'yellowMarker',
								class: 'marker-yellow',
								title: 'Yellow marker',
								color: '#cac407',
								type: 'marker'
							}
						]
					},
					table: {
						tableProperties: {
							// The default styles for tables in the editor.
							// They should be synchronized with the content styles.
							defaultProperties: {
								borderStyle: 'solid',
								borderColor: 'hsl(90, 75%, 60%)',
								borderWidth: '3px',
								alignment: 'left',
								width: '550px',
								height: '450px'
							},
							// The default styles for table cells in the editor.
							// They should be synchronized with the content styles.
							tableCellProperties: {
								defaultProperties: {
									horizontalAlignment: 'center',
									verticalAlignment: 'bottom',
									padding: '10px'
								}
							}
						}
					}
            },
			isViewMode : !hasEditPermission('editor') 
		};
	},
	mounted() {
		analytics.logEvent('screen_view', {
			firebase_screen: 'Editor',
			firebase_screen_class: 'Collaboration',
		});
		this.$store.dispatch('fetchEditedAgreement', {
			id: this.$route.params.id,
		});
	},
	methods: {
		indent(editor) {
			const parent = editor.dom.getParent(
				editor.selection.getNode(),
				'ol'
			);
			this.classLevels.some((className, index) => {
				if (editor.dom.hasClass(parent, className)) {
					editor.dom.removeAllAttribs(parent, true);
					editor.dom.removeClass(parent, className);
					editor.dom.addClass(parent, this.classLevels[index + 1]);

					editor.save()
					return true;
				}
			});
		},
		initEditor(_, editor) {
			editor.on('keydown', (e) => {
				if (e.key === 'Tab' && e.shiftKey === false) {
					e.preventDefault();

					this.indent(editor);
					return false;
				}
				// else if (e.key === 'Tab' && e.shiftKey === true) {
				// 	e.preventDefault();
				// 	return false;
				// }
			});

			editor.on('ExecCommand', (e) => {
				if (e.command === 'indent') {
					e.preventDefault();

					this.indent(editor);
					return false;
				}
				// else if (e.command === 'outdent') {
				// 	e.preventDefault();
				// 	return false;
				// }
			});
		},
		onExecCommand() {},
		indentInCK(editor) {
			editor.model.change( writer => {
				writer.insertText( 'foo', editor.model.document.selection.getFirstPosition() );
			} );
			const findAncestor = editor.editing.view.attributeelement.parent;
			// const parent = editor.dom.getParent(
			// 	editor.selection.getNode(),
			// 	'ol'
			// );
			// this.classLevels.some((className, index) => {
			// 	if (editor.dom.hasClass(parent, className)) {
			// 		editor.dom.removeAllAttribs(parent, true);
			// 		editor.dom.removeClass(parent, className);
			// 		editor.dom.addClass(parent, this.classLevels[index + 1]);

			// 		editor.save()
			// 		return true;
			// 	}
			// });
		},
		initEditorInCK(editor) {
			editor.editing.view.document.on( 'keydown', ( event, data ) => {
				if (data.keyCode === 9 && data.shiftKey === false) {
					// // console.log('Tab')
					data.preventDefault();
					this.indentInCK(editor);
					return false;
				}
			}, { priority: 'high' } );
		},
		initEditorInCKUpdated(editor){
			let classesWhilePressingEnter;
			// editor.commands.get( 'enter' ).on( 'execute', () => {
			// editor.model.change( writer => {
			// const position = editor.model.document.selection.getFirstPosition();
			// writer.insertText( ';', position );
			// } );

			// const selectedBlocks = Array.from( editor.model.document.selection.getSelectedBlocks() );
			// const firstSelectedBlock = selectedBlocks[ 0 ];

			// if ( firstSelectedBlock && firstSelectedBlock.hasAttribute( 'listType' ) ) {
			// 	classesWhilePressingEnter = firstSelectedBlock.getAttribute( 'htmlListAttributes' ).classes;
			// } else {
			// 	classesWhilePressingEnter;
			// }
			// }, { priority: 'high' } );



			editor.editing.view.document.on( 'keydown', ( event, data ) => {
				if (data.keyCode === 9 && data.shiftKey === false) {
					data.preventDefault();
					editor.execute( 'indentList' );
					if (classesWhilePressingEnter){
						this.classLevels.some((className, index) => {
							if (classesWhilePressingEnter.includes(className) ) {
								const selectedBlocks = Array.from( editor.model.document.selection.getSelectedBlocks() );
								const firstSelectedBlock = selectedBlocks[ 0 ];

								if ( firstSelectedBlock && firstSelectedBlock.hasAttribute( 'listType' ) ) {
									editor.model.change( writer => {
									const htmlListAttributes = firstSelectedBlock.getAttribute( 'htmlListAttributes' );

								if ( htmlListAttributes.classes ) {
									htmlListAttributes.push( this.classLevels[index + 1] );
								} else {
									htmlListAttributes.classes = [this.classLevels[index + 1]];
								}
									writer.setAttribute( 'htmlListAttributes', htmlListAttributes, firstSelectedBlock );
								} );

									editor.editing.reconvertItem( firstSelectedBlock );
								}
							}
						})
					}
				} else if(data.keyCode === 13){
					const selectedBlocks = Array.from( editor.model.document.selection.getSelectedBlocks() );
					const firstSelectedBlock = selectedBlocks[ 0 ];

					if ( firstSelectedBlock && firstSelectedBlock.hasAttribute( 'listType' ) ) {
						classesWhilePressingEnter = firstSelectedBlock.getAttribute( 'htmlListAttributes' ).classes;
					} else {
						classesWhilePressingEnter;
					}
				}
			}, { priority: 'high' } );
		},
		onExecCommand() {},
		save() {
			this.$store
				.dispatch('updateEditedAgreement', this.editorHtml)
				.then(() => {
					analytics.logEvent('edited_agreement_saved');
				});
		},
		download() {
			window.open(
				`${window.location.origin}${window.location.pathname}/preview`
			);
		},
		downloadDraft(){
			window.open(
				`${window.location.origin}${window.location.pathname}/preview?draft=true`
			);
		},
		update() {
			const newElement = this.$createElement;
			const messageConent = newElement(
				'div',
				{ class: ['text-center'] },
				[
					newElement(
						'p',
						{},
						'Updating will pull any new data from the Agreement Builder into the editor and any changes will be lost.'
					),
				]
			);
			this.$bvModal
				.msgBoxConfirm([messageConent], {
					title: 'Heads Up!',
					size: 'md',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: 'I Understand',
					cancelTitle: "I'm not ready",
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
				})
				.then((accepted) => {
					if (accepted) {
						const updatedAgreement = generateDocument({
							agreementData: this.agreementData,
						});
						this.editorHtml = updatedAgreement;
						this.$store.dispatch(
							'updateEditedAgreement',
							updatedAgreement
						);
						analytics.logEvent('edited_agreement_updated');
					}
				})
				.catch(() => {});
		},
		async exportToClio(){
			try
			{
				const clioExport = firebaseApp.functions('northamerica-northeast1').httpsCallable('clioApis-clioExport');
				const accessTokenClio=JSON.parse(localStorage.getItem('cliotoken'))
				let header={
					authorization: accessTokenClio.access_token,
					"name": "Divii Uploads",
					"parent": {
						"id": 0,
						"type": 'Folder'
					},
					"fileName": "SeparationAgreement.pdf",
					"restore": false
				}
				const clioUploadSuccess = await clioExport(header)
				.then(linkResponse=>{
					if(linkResponse){
					}
				});
				// console.log("clioUploadSuccess:", clioUploadSuccess)
				
			}catch(error){
				// console.log("error folder uploading:",error)
			}
			// this.download();
		}
	},
	watch: {
		editorHtml: {
			handler: debounce(function () {
				this.save();
			}, 1000),
		},
	},
};
</script>

<style>
@import '../assets/css/printstyles.css';

#container {
    /* To create the column layout. */
    display: flex;

    /* To make the container relative to its children. */
    position: relative;
    max-height: calc(100vh-100px);
}
/* ::marker{
	content: '';
} */

/* .one > li  br,.two > li br,.three > li br,.four > li br,.five > li br,.six > li br,.seven > li br,.eight > li br,.nine > li br{
	content: '';
} */

/* .seven > li > .ck-list-bogus-paragraph, .eight > li > .ck-list-bogus-paragraph{
	display: unset !important;
} */

.one > li::marker,.two > li::marker,.three > li::marker,.four > li::marker,.five > li::marker,.six > li::marker,.seven > li::marker,.eight > li::marker,.nine > li::marker,.ten > li::marker,.eleven > li::marker,.twelve > li::marker{
	content: '';
}

.ck-editor__editable .ck-list-bogus-paragraph{
	display: unset !important;
}

.ck-editor__editable .ck-list-bogus-paragraph:has(> br){
	display: -webkit-inline-box !important;
}

p:empty {
  display:none
}

.ck-editor__top{
	/* position: fixed !important;
	bottom:0 !important; */
	position: sticky !important;
    top: 0px !important;
    z-index: 999 !important;
}

.ck-editor{
	margin-top: 1rem;
    overflow-y: scroll;
    max-height: 65vh;
}

@media (max-width: 768px) {
    .ck-editor{
		padding-bottom: 4.5rem !important;
    }
	.ck-editor::-webkit-scrollbar{
		width: 0.7rem !important;
	}
}


</style>

<template>
	<div>
		<b-card class="mt-2 custom-header-wrapper">
			<h4 slot="header" class="section-heading">Editor</h4>
		</b-card>
		<b-row class="my-3">
			<b-col cols="7" class="hide-mobile"></b-col>
			<b-col class="col-sm-5 col-12">
				<div class="float-right">
					<b-button-group data-testid="editor-buttons">
						<b-button
							variant="secondary"
							@click="update"
							data-testid="editor-update-button"
							:disabled="isViewMode"
						>
							Update
						</b-button>
						<b-button
							variant="secondary"
							data-testid="editor-download-draft"
							@click="downloadDraft"
						>
							Download Draft
						</b-button>
						<!-- <b-button
							variant="primary"
							@click="exportToClio"
							data-testid="export-to-clio"
						>
							Export To Clio
						</b-button> -->
						<!-- <b-button
							variant="primary"
							:disabled="!canDownload"
							@click="download"
							data-testid="editor-download"
						>
							Download
						</b-button> -->
					</b-button-group>
					
				</div>
			</b-col>
		</b-row>
		<div v-if="editorData" class="preview">
			
			<ckeditor :editor="editor" v-model="editorData" :config="editorConfig" 
	  			@ready="initEditorInCKUpdated" :disabled="isViewMode"></ckeditor>

			<!-- <editor
				v-model="editorData"
				:api-key="VUE_APP_TINY_MCE_API_KEY"
				:init="{
					menubar: '',
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount',
					],
					toolbar:
						'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
					browser_spellcheck: true,
					contextmenu: false,
				}"
				:inline="true"
				@onInit="initEditor"
				@onExecCommand="onExecCommand"
			/> -->
		</div>
		<div
			v-else
			class="
				text-center
				w-100
				d-flex
				justify-content-center
				align-items-center
			"
			style="height: 500px"
		>
			<b-spinner
				style="width: 3rem; height: 3rem"
				variant="primary"
				label="Loading..."
			/>
		</div>
	</div>
</template>
