<script>
import partnerNames from '@/mixins/partnerNames';
import partnerChoices from '@/mixins/partnerChoices';
import {hasEditPermission} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [partnerNames, partnerChoices],
	props: ['agreementData'],
	data() {
		return {
			isViewMode : !hasEditPermission("section-final-clauses") 
		};
	},
	computed: {
		getConclusions() {
			return this.agreementData.conclusions;
		},
		responsibleForLegalAdviceOptions() {
			return [
				{   text: 'Each party is responsible for their own costs',
					value: 'each-party',
				},
				{
					text: 'Parties will share overall costs incurred equally',
					value: 'share-costs',
				},
				{
					text: 'None',
					value: 'none',
				}];
		},
		disputeResolutionProcessOptions(){
			return [
				{ text: 'Mediation', value: 'mediation' },
				{ text: 'Arbitration', value: 'arbitration' },
				{
					text: 'Collaborative Family Law',
					value: 'collaborative-family-law',
				},
				{
					text: 'Supreme Court Proceedings',
					value: 'supreme-court-proceedings',
				},
				{
					text: 'Provincial and Supreme Court Proceedings',
					value: 'provincial-and-supreme-court-proceedings',
				},
				{
					text: 'None',
					value: 'none',
				},
			];
		},
		divorcePaymentOptions(){
			let options=[{
							text: 'The parties will share the cost',
							value: 'shared',
						}];
			if(this.agreementData.conclusions.divorceApplication=='either'){
				options.push({
							text: 'The party who applied for the Divorce',
							value: 'appliedParty',
						})
			}
			return options
		}
	},
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Conclusions</h4>
		<radio-group
			v-model="getConclusions.disputeResolutionProcess"
			class="my-3"
			:agreement-data="agreementData"
			input-cols="4"
			label="What dispute resolution process will the parties use if a dispute arises?"
			select
			:options="disputeResolutionProcessOptions"
			testid="dispute-resolution-process"
			:length="'long'"
			:disabled="isViewMode"
		/>
		<radio-group
			v-model="getConclusions.responsibleForLegalAdvice"
			class="my-3"
			:agreement-data="agreementData"
			input-cols="4"
			label="Who is responsible for the costs of Independent Legal Advice?"
			select
			partner
			:options="responsibleForLegalAdviceOptions"
			testid="responsible-for-legal-advice"
			:length="'long'"
			:disabled="isViewMode"
		/>
		<input-group
			v-model="getConclusions.durationToPerformUnderAgreementAfterSigning"
			class="my-3"
			reset-undefined
			input-cols="4"
			label="How long will parties have to perform under the agreement from the date the agreement is signed?"
			placeholder="30 days"
			testid="duration-to-perform-under-agreement-after-signing"
			:disabled="isViewMode"
		/>
		<input-group
			v-model="getConclusions.disclosureAndCompensationBeRequired"
			class="my-3"
			reset-undefined
			label="At what threshold amount will disclosure and compensation become required?"
			dollar
			placeholder="1,000"
			testid="disclosure-and-compensation-be-required"
			:disabled="isViewMode"
		/>
		<conditional-group
			class="my-3"
			:condition="agreementData.relationship.status === 'Married'"
		>
			<b-form-group
				id="input-divorceApplication"
				input-cols="8"
				label="Which party will apply for divorce?"
				label-for="divorceApplication"
			>
				<b-form-select
					id="divorceApplication"
					v-model="getConclusions.divorceApplication"
					size="sm"
					:options="[
						...partnerChoices,
						{ text: 'Either', value: 'either' },
					]"
					data-testid='relationship-divorce-application'
					class="col-lg-8"
					:disabled="isViewMode"
				/>
			</b-form-group>

			<b-form-group
				id="input-divorcePayment"
				input-cols="8"
				label="Who will pay for the divorce application?"
				label-for="divorcePayment"
			>
				<b-form-select
					id="divorcePayment"
					v-model="getConclusions.divorcePayment"
					size="sm"
					:options="[
						...partnerChoices,
						...divorcePaymentOptions]"
					data-testid='relationship-divorce-payment'
					class="col-lg-8"
					:disabled="isViewMode"
				/>
			</b-form-group>
		</conditional-group>
	</b-card>
</template>
