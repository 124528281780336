<script>
// "Kanye will have parenting time from Saturday in Week 1 when Kanye picks the Children up at 2:30 p.m. until Tuesday in Week 2 when Kim picks the Children up at 2:30 p.m."
import format from 'date-fns/esm/format';
import getDate from 'date-fns/getDate';

export default {
	props: [
		'event',
		'partner1Name',
		'partner2Name',
		'lastEvent',
		'firstEvent',
		'firstAndLastAreSame',
		'children',
		'parentingTimeType',
	],
	computed: {
		parentingTimeTypeText() {
			return this.parentingTimeType == 'contact'
				? 'contact time'
				: 'parenting time';
		},
	},
	methods: {
		getDayOfWeek(date) {
			return date ? format(date, 'EEEE') : '';
		},
		getTime(date) {
			return date ? format(date, 'h:mm aaa') : '';
		},
		getWeekNumber(date) {
			return getDate(date) < 9 ? 1 : 2;
		},
		getPartnerName(partner) {
			return partner === 'partner1'
				? this.partner1Name
				: this.partner2Name;
		},
		getOtherPartnerName(partner) {
			return partner === 'partner1'
				? this.partner2Name
				: this.partner1Name;
		},
	},
};
</script>

<template>
	<div>
		<span v-if="firstAndLastAreSame && event.id === lastEvent.id">
			{{ getPartnerName(event.class) }} will have
			{{ parentingTimeTypeText }} from
			{{ getDayOfWeek(lastEvent.start) }} at
			{{ getTime(lastEvent.start) }} in Week
			{{ getWeekNumber(lastEvent.start) }} until
			{{ getDayOfWeek(firstEvent.end) }} at
			{{ getTime(firstEvent.end) }} in Week
			{{ getWeekNumber(firstEvent.end) }}.
		</span>
		<span v-else>
			{{ getPartnerName(event.class) }} will have
			{{ parentingTimeTypeText }} from {{ getDayOfWeek(event.start) }} at
			{{ getTime(event.start) }} in Week
			{{ getWeekNumber(event.start) }} until
			{{ getDayOfWeek(event.end) }} at {{ getTime(event.end) }} in Week
			{{ getWeekNumber(event.end) }}.
		</span>

		<div>
			<b>Transition: </b>
			<span v-if="event.transition == 'pickUp'">
				{{ getOtherPartnerName(event.class) }} picks up {{ children }}
			</span>
			<span v-else>
				{{ getPartnerName(event.class) }} drops off {{ children }}
			</span>
		</div>

		<div v-if="event.transitionDetails">
			<b>
				{{
					event.transition == 'pickUp'
						? `Pick Up Details`
						: `Drop Off Details`
				}}: </b
			>{{ event.transitionDetails }}
		</div>
	</div>
</template>
