<script>
import RadioGroup from '../Form/RadioGroup.vue';
import {hasEditPermission,currentRoleLevelName} from '@/mixins/roleLevelPermissions';
export default {
	components: { RadioGroup },
	// mixins: [roleLevelPermissions],
	props: ['partnerData', 'title', 'partner', 'id'],
	data(){
		return{
			// label: this.clientUserAgreement ? 'Are you retired?':'Is this party retired?' ,
			isViewMode : (this.partner == 'party2') ? !hasEditPermission('section-other-partner-details') : false
		};
	},
	computed:{
		clientUserAgreement(){
			return currentRoleLevelName == 'client-sharing-user' ? true : false;
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">{{ title }}</h4>
		<address-input-group v-model="partnerData.address" class="mb-3" :testid="partner+'-address'" :disabled="isViewMode"/>

		<date-input
			v-model="partnerData.birthdate"
			label="Birthdate"
			class="mb-3"
			:testid="partner+'-dob'"
			:disabled="isViewMode"
		/>
		<input-group
				v-model="partnerData.mail"
				reset-undefined
				class="mb-2"
				input-cols="8"
				label="Email"
				:testid="partner+'-email'"
				:disabled="isViewMode"
		/>
		<b-form-group
			v-if="!clientUserAgreement"
			id="input-employed"
			label="Is this party employed?"
			:label-for="id"
			:disabled="isViewMode"
		>
			<b-form-select
				:id="id"
				v-model="partnerData.employed"
				:options="[
					{ text: 'Yes', value: true },
					{ text: 'No', value: false,
					},
				]"
				:data-testid="partner+'-employed'"
				class="col-lg-8 col-7"
				:disabled="isViewMode"
			/>
		</b-form-group>
		<b-form-group
			v-else
			id="input-employed"
			label="Are you employed?"
			label-for="employed"
			:disabled="isViewMode"
		>
			<b-form-select
				id="employed"
				v-model="partnerData.employed"
				:options="[
					{ text: 'Yes', value: true },
					{ text: 'No', value: false,
					},
				]"
				:data-testid="partner+'-employed'"
				class="col-lg-8"
				:disabled="isViewMode"
			/>
		</b-form-group>
		<conditional-group
			class="my-3"
			:condition="partnerData.employed === true"
		>
				
			<input-group
				v-model="partnerData.occupation"
				class="mb-3"
				reset-undefined
				input-cols="8"
				label="Occupation"
				:testid="partner+'-occupation'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<conditional-group
			class="my-3"
			:condition="partnerData.employed === false"
		>			
			<yes-no-choice
				v-model="partnerData.retired"
				:label="clientUserAgreement ? 'Are you retired?':'Is this party retired?'"
				class="mb-3"
				:testid="partner+'-retired'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<radio-group
			v-model="partnerData.pronouns"
			class="mb-3"
			select
			input-cols="2"
			label="Pronouns"
			:options="[
				{ text: 'He/Him/His', value: 'male' },
				{ text: 'She/Her/Hers', value: 'female' },
				{ text: 'They/Them/Their', value: 'neutral' },
			]"
			:testid="partner+'-pronouns'"
			:disabled="isViewMode"
		/>
		<input-group
			v-model="partnerData.income"
			dollar
			label="Gross Income"
			class="mb-3"
			:testid="partner+'-gross-income'"
			:disabled="isViewMode"
		/>
		<template v-if="!clientUserAgreement">
		<radio-group
			v-model="partnerData.incomeReason"
			class="mb-3"
			select
			input-cols="5"
			label="How did the parties determine income?"
			:options="[
				'By verifying their Income Tax Returns and other documents',
				{ text: 'By agreement', value: 'agreement' },
				{ text: 'By imputation', value: 'imputation' },
			]"
			:testid="partner+'-income-reason'"
			:length="'long'"
			:disabled="isViewMode"
		/>
	
		<yes-no-choice
			v-model="partnerData.incomeDisclosure"
			label="Did this party fully disclose their income information to the other party?"
			class="mb-3"
			:testid="partner+'-income-disclosure'"
			:disabled="isViewMode"
		/>

		<yes-no-choice
			v-model="partnerData.hasLegalCounsel"
			label="Is this party represented by a lawyer?"
			class="mb-3"
			:testid="partner+'-has-legal-counsel'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="mb-3"
			:condition="partnerData.hasLegalCounsel"
			@reset="partnerData.legalCounselFullName = undefined"
		>
			<input-group
				v-model="partnerData.legalCounselFullName"
				input-cols="8"
				label="Name of Lawyer"
				:testid="partner+'-lawyer-name'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<yes-no-choice
			v-model="partnerData.includeCertificateOfIndependantLegalAdvice"
			label="Would you like to include a certificate of legal advice?"
			class="mb-3"
			:testid="partner+'-certificate-of-legal-advice'"
			:disabled="isViewMode"
		/>
		<additional-clauses v-model="partnerData.additionalClauses" :testid="partner+'-add-clause-'" :disabled="isViewMode" />
	</template>
	</b-card>
</template>
